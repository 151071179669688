import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import "../Styles/Crop.css";
import axios from "axios";

const Crop = () => {
  const [conds, setConds] = useState({
    codndesc: "",
    nitrogen: "",
    phosphorous: "",
    potassium: "",
    temperature: "",
    humidity: "",
    rainfall: "",
    pH: "",
  });
  const [selectedvalue, setSelectedValue] = useState();
  const [crop, setCrop] = useState();

  const checkCondition = () => {
    switch (selectedvalue) {
      case "Rice":
        setConds({
          codndesc: "Optimal Conditions to grow Rice is",
          nitrogen: "Nitrogen - 50 kg/acre",
          phosphorous: "Phosphorous - 12 kg/acre",
          potassium: "Potassium - 12 kg/acre",
          temperature: "Temperature - 21-37 degree Celsius",
          humidity: "Humidity - 60-80  %",
          rainfall: "Rainfall - More than 100 cm",
          pH: "pH - 5.5-6.5 ",
        });
        break;
      case "Maize":
        setConds({
          codndesc: "Optimal Conditions to grow Maize is",
          nitrogen: "Nitrogen - 120 kg/acre",
          phosphorous: "Phosphorous - 60 kg/acre",
          potassium: "Potassium - 40 kg/acre",
          temperature: "Temperature - 18-27 degree Celsius",
          humidity: "Humidity - 55-65  %",
          rainfall: "Rainfall - 50-100 cm",
          pH: "pH - More than 5.5 ",
        });
        break;
      case "Cotton":
        setConds({
          codndesc: "Optimal Conditions to grow Cotton is",
          nitrogen: "Nitrogen - 20 kg/acre",
          phosphorous: "Phosphorous - 10 kg/acre",
          potassium: "Potassium - 10 kg/acre",
          temperature: "Temperature - 20-32 degree Celsius",
          humidity: "Humidity - 43-76  %",
          rainfall: "Rainfall - 50-75 cm",
          pH: "pH - 5.8-68.0 ",
        });
        break;
      case "Jute":
        setConds({
          codndesc: "Optimal Conditions to grow Jute is",
          nitrogen: "Nitrogen - 2.88 kg/acre",
          phosphorous: "Phosphorous - 0.97 5.07 kg/acre",
          potassium: "Potassium - kg/acre",
          temperature: "Temperature - More than 25 degree Celsius",
          humidity: "Humidity - 70-9-  %",
          rainfall: "Rainfall - 160-200 cm",
          pH: "pH - 4.8-5.8 ",
        });
        break;
      case "Coconut":
        setConds({
          codndesc: "Optimal Conditions to grow Coconut is",
          nitrogen: "Nitrogen - 1.3 kg/acre",
          phosphorous: "Phosphorous - 0.3 kg/acre",
          potassium: "Potassium - 0.2 kg/acre",
          temperature: "Temperature - 27 degree Celsius",
          humidity: "Humidity - More than 60  %",
          rainfall: "Rainfall - 35-250 cm",
          pH: "pH - 6.5-7.0 ",
        });
        break;
      case "Papaya":
        setConds({
          codndesc: "Optimal Conditions to grow Papaya is",
          nitrogen: "Nitrogen - 0.25 kg/acre",
          phosphorous: "Phosphorous - 0.25 kg/acre",
          potassium: "Potassium - 0.5 kg/acre",
          temperature: "Temperature - 12-14 degree Celsius",
          humidity: "Humidity - 60-85 %",
          rainfall: "Rainfall - 100-120cm",
          pH: "pH - 6.5-7.0 ",
        });
        break;
      case "Orange":
        setConds({
          codndesc: "Optimal Conditions to grow Orange is",
          nitrogen: "Nitrogen - 12 kg/acre",
          phosphorous: "Phosphorous - 12 kg/acre",
          potassium: "Potassium - 17 kg/acre",
          temperature: "Temperature - 25 degree Celsius",
          humidity: "Humidity - Above 50 %",
          rainfall: "Rainfall - 100-120 cm",
          pH: "pH - 5.5-6.5",
        });
        break;
      case "Apple":
        setConds({
          codndesc: "Optimal Conditions to grow Apple is",
          nitrogen: "Nitrogen - 12 kg/acre",
          phosphorous: "Phosphorous - 12 kg/acre",
          potassium: "Potassium - 12 kg/acre",
          temperature: "Temperature - 21-25  degree Celsius",
          humidity: "Humidity - More than 90 %",
          rainfall: "Rainfall - 100-125 cm",
          pH: "pH - 5.5-6.5 ",
        });
        break;
      case "Muskmelon":
        setConds({
          codndesc: "Optimal Conditions to grow Muskmelon is",
          nitrogen: "Nitrogen - 40 kg/acre",
          phosphorous: "Phosphorous - 60 kg/acre",
          potassium: "Potassium - 30 kg/acre",
          temperature: "Temperature - 25-35 degree Celsius",
          humidity: "Humidity - 95 %",
          rainfall: "Rainfall - 40-60 cm",
          pH: "pH - 6.5-7.5",
        });
        break;
      case "Watermelon":
        setConds({
          codndesc: "Optimal Conditions to grow Watermelon is",
          nitrogen: "Nitrogen - 20 kg/acre",
          phosphorous: "Phosphorous - 20 kg/acre",
          potassium: "Potassium - 20 kg/acre",
          temperature: "Temperature - 18-20 degree Celsius",
          humidity: "Humidity - More than 90 %",
          rainfall: "Rainfall - 40-60 cm",
          pH: "pH - 6.0-6.5 ",
        });
        break;
      case "Grapes":
        setConds({
          codndesc: "Optimal Conditions to grow Grapes is",
          nitrogen: "Nitrogen - 10 kg/acre",
          phosphorous: "Phosphorous - 10 kg/acre",
          potassium: "Potassium - 10 kg/acre",
          temperature: "Temperature - 10-40  degree Celsius",
          humidity: "Humidity - 90-95 %",
          rainfall: "Rainfall - Less than 90 cm",
          pH: "pH - 6.5-8.0",
        });
        break;
      case "Mango":
        setConds({
          codndesc: "Optimal Conditions to grow Mango is",
          nitrogen: "Nitrogen - 10 kg/acre",
          phosphorous: "Phosphorous - 20 kg/acre",
          potassium: "Potassium - 20 kg/acre",
          temperature: "Temperature - more than 10 degree Celsius",
          humidity: "Humidity -  atleast 50%",
          rainfall: "Rainfall - 89 to 101.5cm",
          pH: "pH - 5.5-7.5 ",
        });
        break;
      case "Banana":
        setConds({
          codndesc: "Optimal Conditions to grow Banana is",
          nitrogen: "Nitrogen - 8 kg/acre",
          phosphorous: "Phosphorous - 10 kg/acre",
          potassium: "Potassium - 8 kg/acre",
          temperature: "Temperature - 31-32 degree Celsius",
          humidity: "Humidity - 75-85 %",
          rainfall: "Rainfall - 170cm",
          pH: "pH - 5",
        });
        break;
      case "Pomegranate":
        setConds({
          codndesc: "Optimal Conditions to grow Pomegranate is",
          nitrogen: "Nitrogen - 8 kg/acre",
          phosphorous: "Phosphorous - 8 kg/acre",
          potassium: "Potassium - 8 kg/acre",
          temperature: "Temperature - 0-10 degree Celsius",
          humidity: "Humidity - 90 %",
          rainfall: "Rainfall - 50-80 cm",
          pH: "pH - 2.93-3.20",
        });
        break;
      case "Lentil":
        setConds({
          codndesc: "Optimal Conditions to grow Lentil is",
          nitrogen: "Nitrogen - 20kg/acre",
          phosphorous: "Phosphorous - 40 kg/acre",
          potassium: "Potassium - 20 kg/acre",
          temperature: "Temperature - 18-30 degree Celsius",
          humidity: "Humidity -  12-14%",
          rainfall: "Rainfall - 30-45cm",
          pH: "pH - 6-8",
        });
        break;
      case "Blackgram":
        setConds({
          codndesc: "Optimal Conditions to grow Blackgram is",
          nitrogen: "Nitrogen - 20 kg/acre",
          phosphorous: "Phosphorous - 40 kg/acre",
          potassium: "Potassium - 40 kg/acre",
          temperature: "Temperature - 25-35 degree Celsius",
          humidity: "Humidity -  70-75 %",
          rainfall: "Rainfall - 70-90cm",
          pH: "pH - 6.5-8.0",
        });
        break;
      case "Mungbean":
        setConds({
          codndesc: "Optimal Conditions to grow Mungbean is",
          nitrogen: "Nitrogen - 2kg/acre",
          phosphorous: "Phosphorous - 1kg/acre",
          potassium: "Potassium - 0.5kg/acre",
          temperature: "Temperature - 30 degree Celsius",
          humidity: "Humidity - 12 %",
          rainfall: "Rainfall - 40-55cm",
          pH: "pH - 6.2-7.2",
        });
        break;
      case "Mothbeans":
        setConds({
          codndesc: "Optimal Conditions to grow MothBeans is",
          nitrogen: "Nitrogen - 40kg/acre",
          phosphorous: "Phosphorous - 40 kg/acre",
          potassium: "Potassium - 20 kg/acre",
          temperature: "Temperature - 24-32 degree Celsius",
          rainfall: "Rainfall - less than 50 cm",
        });
        break;
      case "Pigoenpeas":
        setConds({
          codndesc: "Optimal Conditions to grow Pigeonpeas is",
          nitrogen: "Nitrogen - 30 kg/acre",
          phosphorous: "Phosphorous - 50 kg/acre",
          potassium: "Potassium - 30 kg/acre",
          temperature: "Temperature - 26-30 degree Celsius",
          humidity: "Humidity - 50-60 %",
          rainfall: "Rainfall - 91.66cm",
          pH: "pH - 5-7",
        });
        break;
      case "Kidneybeans":
        setConds({
          codndesc: "Optimal Conditions to grow KidneyBeans is",
          nitrogen: "Nitrogen - 40 kg/acre",
          phosphorous: "Phosphorous - 25 kg/acre",
          potassium: "Potassium - 0 kg/acre",
          temperature: "Temperature - 93-99 degree Celsius",
          humidity: "Humidity - 12-14 %",
          rainfall: "Rainfall - 60-150cm",
          pH: "pH - 6.5",
        });
        break;
      case "Chikpeas":
        setConds({
          codndesc: "Optimal Conditions to grow ChikPeas is",
          nitrogen: "Nitrogen - 20 kg/acre",
          phosphorous: "Phosphorous - 60 kg/acre",
          potassium: "Potassium - 25 kg/acre",
          temperature: "Temperature - 10-15 degree Celsius",
          humidity: "Humidity -  14%",
          rainfall: "Rainfall - 60-100 cm",
          pH: "pH - 4-6 ",
        });
        break;
      case "Coffee":
        setConds({
          codndesc: "Optimal Conditions to grow Coffee is",
          nitrogen: "Nitrogen - 2.1 kg/acre",
          phosphorous: "Phosphorous - 0.3 kg/acre",
          potassium: "Potassium - 0.3 kg/acre",
          temperature: "Temperature - 18-21 degree Celsius",
          humidity: "Humidity - 9.5-11 %",
          rainfall: "Rainfall - 1500-2000cm",
          pH: "pH - 4.8-5.1",
        });
        break;

      default:
        setConds({ codndesc: "error occured" });
        break;
    }
  };

  // const [field, setField] = useState({
  //   nitrogen: "",
  //   phosphorous: "",
  //   potassium: "",
  //   temperature: "",
  //   humidity: "",
  //   rainfall: "",
  //   pH: "",
  // });
  const [nitro, setNitro] = useState();
  const [phos, setPhos] = useState();
  const [potas, setPotas] = useState();
  const [humid, setHumid] = useState();
  const [temp, setTemp] = useState();
  const [rain, setRain] = useState();
  const [pH, setPH] = useState();
  // const changeNitro = (e) => {
  //   let a = Number(e.target.value);
  //   setField({ nitrogen: a });
  // };
  // const changePhos = (e) => {
  //   let b = Number(e.target.value);
  //   setField({ phosphorous: b });
  // };
  // const changePotas = (e) => {
  //   setField({ potassium: e.target.value });
  // };
  // const changeTemp = (e) => {
  //   setField({ temperature: e.target.value });
  // };
  // const changeHumid = (e) => {
  //   setField({ humidity: e.target.value });
  // };
  // const changeRain = (e) => {
  //   setField({ rainfall: e.target.value });
  // };
  // const changePh = (e) => {
  //   setField({ pH: e.target.value });
  // };

  const sendFlask = () => {
    let farm_param = new FormData();
    farm_param.append("nitrogen", nitro);
    farm_param.append("phosphorous", phos);
    farm_param.append("potassium", potas);
    farm_param.append("temperature", temp);
    farm_param.append("humidity", humid);
    farm_param.append("rainfall", rain);
    farm_param.append("ph", pH);
    console.log(nitro);
    console.log(phos);
    console.log(potas);
    console.log(temp);
    console.log(humid);
    console.log(rain);
    console.log(pH);

    axios
      .post("http://topgrade.co.in/MLModels", farm_param)
      .then((response) => {
        console.log(response);
        let des = String(response.data);
        let crop_mess =
          "Crops that can be grown in the given condition are " + des;
        setCrop(crop_mess);
      })
      .catch((err) => {
        console.log("hi");
      });
  };

  return (
    <div className="cf">
      <Navbar />
      <div className="croppredicfp">
        <div className="intelpred">
          <h1>Predict Crop Best Suitable based on Environmental Conditions</h1>
          <p className="desc">
            A logistical regression model which uses parameters - Nitrogen,
            Phosphorous, Potassium, Temperature, Humidity, Rainfall and pH to
            find the best crop that can grown in the given parameters. The model
            is trained using a 2200 data entries. The accuracy of the model is
            evaluated to be 97%
          </p>
          <div className="eform ip">
            <form className="fona">
              <div className="field1">
                <label> Environmental Conditions </label>
                <input
                  placeholder="Nitrogen in (kg/acre)"
                  value={nitro}
                  onChange={(e) => setNitro(e.target.value)}
                  type="number"
                />
                <input
                  placeholder="Phosphorous in (kg/acre)"
                  value={phos}
                  onChange={(e) => setPhos(e.target.value)}
                  type="number"
                />
                <input
                  placeholder="Potassium in (kg/acre)"
                  value={potas}
                  onChange={(e) => setPotas(e.target.value)}
                  type="number"
                />
                <input
                  placeholder="Temperature (in Celsius)"
                  value={temp}
                  onChange={(e) => setTemp(e.target.value)}
                  type="number"
                />
                <input
                  placeholder="Humidity (in percentage)"
                  value={humid}
                  onChange={(e) => setHumid(e.target.value)}
                  type="number"
                />
                <input
                  placeholder="Rainfall (in cm)"
                  value={rain}
                  onChange={(e) => setRain(e.target.value)}
                  type="number"
                />
                <input
                  placeholder="pH (Range 0-14)"
                  value={pH}
                  onChange={(e) => setPH(e.target.value)}
                  type="number"
                />
              </div>
            </form>

            <a className="button" onClick={sendFlask}>
              Check
            </a>
          </div>
          <h4>{crop}</h4>
        </div>
        <div className="bestcond">
          <h1>Check Suitable condition for plants</h1>
          <p className="desc">
            Select a crop from the drop down menu to know the optimum conditions
            to grow that crop
          </p>
          <div className="eform bc">
            <div className="fobc">
              <label for="crops">Choose a crop:</label>
              <select
                value={selectedvalue}
                onChange={(e) => setSelectedValue(e.target.value)}
              >
                <option value="#">Select crop:</option>
                <option value="Rice">Rice</option>
                <option value="Maize">Maize</option>
                <option value="Cotton">Cotton</option>
                <option value="Coconut">Coconut</option>
                <option value="Papaya">Papaya</option>
                <option value="Orange">Orange</option>
                <option value="Apple">Apple</option>
                <option value="Muskmelon">Muskmelon</option>
                <option value="Watermelon">Watermelon</option>
                <option value="Grapes">Grapes</option>
                <option value="Mango">Mango</option>
                <option value="Banana">Banana</option>
                <option value="Pomegranate">Pomegranate</option>
                <option value="Lentil">Lentil</option>
                <option value="Blackgram">Blackgram</option>
                <option value="Mungbean">Mungbean</option>
                <option value="Mothbeans">Mothbeans</option>
                <option value="Pigeonpeas">Pigeonpeas</option>
                <option value="Kidneybeans">Kidneybeans</option>
                <option value="Chikpeas">Chikpeas</option>
                <option value="Coffee">Coffee</option>
              </select>
              <a className="button" onClick={checkCondition}>
                Check
              </a>
            </div>
          </div>
          <div className="suitcond">
            <h2>{conds.codndesc}</h2>
            <h4>{conds.nitrogen}</h4>
            <h4>{conds.phosphorous}</h4>
            <h4>{conds.potassium}</h4>
            <h4>{conds.temperature}</h4>
            <h4>{conds.humidity}</h4>
            <h4>{conds.rainfall}</h4>
            <h4>{conds.pH}</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Crop;
