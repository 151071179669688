import "./App.css";
import Navbar from "./Components/Navbar";
import { Hero } from "./Components/Hero";
import { AboutUs } from "./Components/AboutUs";
import { Learning } from "./Components/Learning";

function App() {
  return (
    <div className="App">
      <div className="main_back">
        <Navbar />
        <Hero />
        <Learning />
        <AboutUs />
      </div>
    </div>
  );
}

export default App;
