import React, { useState, useEffect } from "react";
import "../Styles/TextSentimentAnalysis.css";

import Sentiment from "sentiment";
const TextSentimentAnalysis = () => {
  const sentiment = new Sentiment();
  const [inputText, setInputText] = useState("");
  const [result, setResult] = useState();

  useEffect(() => {
    const tempResult = sentiment.analyze(inputText);
    setResult(tempResult);
    console.log(tempResult);
  }, [inputText]);

  return (
    <div className="sentimentheader">
      <h1 className="sentiment_title">Text Sentiment Analysis</h1>
      <p className="icon">
        {result?.score === 0 ? "😐" : result?.score < 0 ? "😞" : "🙂"}
      </p>
      <input
        type="text"
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        placeholder="Enter text here"
      />
      <div className="result">
        {result?.negative.map((item, index) => (
          <span className="negative">{item}</span>
        ))}
        {result?.positive.map((item, index) => (
          <span className="positive">{item}</span>
        ))}
      </div>
      <h4 className="Heading">
        {result?.score === 0
          ? "Sentiment : Neutral"
          : result?.score < 0
          ? "Sentiment : Negative"
          : "Sentiment : Positive"}
      </h4>
    </div>
  );
};

export default TextSentimentAnalysis;
