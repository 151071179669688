import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Explore from "./Pages/Explore";
import Crop from "./Pages/Crop";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import EmotionDetection from "./Pages/EmotionDetection";
import ObjectDetection from "./Pages/ObjectDetection";
import Gesture from "./Pages/Gesture";
import TextSentimentAnalysis from "./Pages/TextSentimentAnalysis";

const root = ReactDOM.createRoot(document.getElementById("root"));
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "Explore",
    element: <Explore />,
  },
  {
    path: "EmotionDetection",
    element: <EmotionDetection />,
  },
  {
    path: "ObjectDetection",
    element: <ObjectDetection />,
  },
  {
    path: "Crop",
    element: <Crop />,
  },
  {
    path: "Gesture",
    element: <Gesture />,
  },
  {
    path: "SentimentAnalysis",
    element: <TextSentimentAnalysis />,
  },
]);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
