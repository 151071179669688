import aboutus_hero from "../Assets/images/aboutus_hero.png";
import AOS from "aos";
import React, { useEffect } from "react";
import "aos/dist/aos.css";
import "../Styles/AboutUs.css";

export const AboutUs = () => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <div className="About_Us">
      <div className="About">
        <div className="About_img" data-aos="fade">
          <img src={aboutus_hero} className="heroimage"></img>
        </div>
        <div className="About_cont">
          <h1 data-aos="fade-right">
            Dedicated towards building the largest learner community
          </h1>
          <p data-aos="fade-left">
            TopGrade unites students through captivating science experiences.
            Our mission is to foster curiosity, collaboration, and a passion for
            discovery. By offering hands-on experiments and interactive learning
            opportunities, we ensure that every student can explore their
            scientific interests and develop innovative thinking. TopGrade
            creates an inclusive environment where young minds can connect,
            share ideas, and inspire each other. We believe in the power of
            community to enhance learning and drive progress. Join us in
            creating a dynamic, supportive network of learners who are ready to
            make a meaningful impact on the world.
          </p>
        </div>
      </div>
    </div>
  );
};
