import "../Styles/learning.css";
import scientist from "../Assets/images/scientist.svg";

export const Learning = () => {
  return (
    <div className="Learning_strip">
      <div className="learning_text_container">
        <h2>Start your Learning Journey</h2>
        <p>
          Check out our curated courses, specially designed for you. Ignite the
          power of science within through our carefully crafted curriculum. Join
          us to explore, experiment, and excel in the fascinating world of
          science.
        </p>
        <button
          class="learning_click"
          onClick={() => {
            window.location.href = "https://learn.topgrade.co.in/";
          }}
        >
          <span class="text">Learn Now</span>
          <span>Courses </span>
        </button>
      </div>
      <div className="learning_img_container">
        <img className="learning_img" src={scientist} alt="logo" />
      </div>
    </div>
  );
};
