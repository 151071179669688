import heroImg from "../Assets/images/Light_Mode_Hero_img.png";
import Typewriter from "typewriter-effect";
import { useState } from "react";
import "../Styles/Hero.css";
export const Hero = () => {
  const [hero_title] = useState({
    title: "Welcome to",
    titleTwo: "TopGrade",
  });

  return (
    <div className="Hero_Banner">
      <div className="Hero_text_container">
        <div className="intro">
          <h2>
            <div className="title">{hero_title.title}</div>
            <div className="titleTwo">{hero_title.titleTwo}</div>
          </h2>
          <div className="text">
            <Typewriter
              options={{
                autoStart: true,
                cursor: " :)",
                loop: true,
                strings: [
                  "Ignite Minds, Inspire Future",
                  "Unleash Scientific Curiosity",
                  "Transforming School Science Fests",
                ],
              }}
            />
          </div>
          <p className="Hero_Content">
            Igniting young minds with interactive science experiences, sparking
            curiosity and innovation, and empowering the next generation of
            visionary scientists .{" "}
          </p>
        </div>
      </div>
      <div className="Hero_img_container">
        {" "}
        <img className="Hero_img" src={heroImg} alt="logo" />
      </div>
    </div>
  );
};
