import React, { useRef, useState } from "react";
import * as tf from "@tensorflow/tfjs";
import * as handpose from "@tensorflow-models/handpose";
import Webcam from "react-webcam";
import "../Styles/Gesture.css";
import { drawHand } from "../utils.js";
import * as fp from "fingerpose";
import ThumbsDownGesture from "../Gestures/ThumbsDown.js";
import MiddleFingerGesture from "../Gestures/MiddleFinger.js";
import OKSignGesture from "../Gestures/OKSign.js";
import PinchedFingerGesture from "../Gestures/PinchedFinger.js";
import PinchedHandGesture from "../Gestures/PinchedHand.js";
import RaisedHandGesture from "../Gestures/RaisedHand.js";
import LoveYouGesture from "../Gestures/LoveYou.js";
import RockOnGesture from "../Gestures/RockOn.js";
import CallMeGesture from "../Gestures/CallMe.js";
import PointUpGesture from "../Gestures/PointUp.js";
import PointDownGesture from "../Gestures/PointDown.js";
import PointRightGesture from "../Gestures/PointRight.js";
import PointLeftGesture from "../Gestures/PointLeft.js";
import RaisedFistGesture from "../Gestures/RaisedFist.js";
import victory from "../Assets/images/victory.png";
import thumbs_up from "../Assets/images/thumbs_up.png";
import thumbs_down from "../Assets/images/thumbs_down.png";
import middle_finger from "../Assets/images/middle_finger.png";
import ok_sign from "../Assets/images/ok_sign.png";
import pinched_finger from "../Assets/images/pinched_finger.png";
import pinched_hand from "../Assets/images/pinched_hand.png";
import raised_hand from "../Assets/images/raised_hand.png";
import love_you from "../Assets/images/love_you.png";
import rock_on from "../Assets/images/rock_on.png";
import call_me from "../Assets/images/call_me.png";
import point_up from "../Assets/images/point_up.png";
import point_down from "../Assets/images/point_down.png";
import point_left from "../Assets/images/point_left.png";
import point_right from "../Assets/images/point_right.png";
import raised_fist from "../Assets/images/raised_fist.png";

function Gesture() {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);

  const [emoji, setEmoji] = useState(null);
  const images = {
    thumbs_up: "Detected Sign - 👍",
    victory: "Detected Sign - ✌️",
    thumbs_down: "Detected Sign - 👎",
    middle_finger: "Detected Sign - 🖕",
    ok_sign: "Detected Sign - 👌",
    pinched_finger: "Detected Sign - 🤌",
    pinched_hand: "Detected Sign - 🤏",
    raised_hand: "Detected Sign - 🖐",
    love_you: "Detected Sign - 🤟",
    rock_on: "Detected Sign - 🤘",
    call_me: "Detected Sign - 🤙",
    point_up: "Detected Sign - 👆",
    point_down: "Detected Sign - 👇",
    point_left: "Detected Sign - 👈",
    point_right: "Detected Sign - 👉",
    raised_fist: "Detected Sign - ✊",
  };

  const runHandpose = async () => {
    const net = await handpose.load();
    //console.log("handpose model loaded");
    // loop and detect hand
    setInterval(() => {
      detect(net);
    }, 100);
  };
  const detect = async (net) => {
    if (
      typeof webcamRef.current !== "undefined" &&
      webcamRef.current != null &&
      webcamRef.current.video.readyState === 4
    ) {
      // get video properties
      const video = webcamRef.current.video;
      const videoWidth = webcamRef.current.video.videoWidth;
      const videoHeight = webcamRef.current.video.videoHeight;
      // set video width and height
      webcamRef.current.video.width = videoWidth;
      webcamRef.current.video.height = videoHeight;
      // set canvas width and height
      canvasRef.current.width = videoWidth;
      canvasRef.current.height = videoHeight;
      // make detection
      const hand = await net.estimateHands(video);

      if (hand.length > 0) {
        const GE = new fp.GestureEstimator([
          fp.Gestures.VictoryGesture,
          fp.Gestures.ThumbsUpGesture,
          ThumbsDownGesture,
          MiddleFingerGesture,
          OKSignGesture,
          PinchedFingerGesture,
          PinchedHandGesture,
          RaisedHandGesture,
          LoveYouGesture,
          RockOnGesture,
          CallMeGesture,
          PointRightGesture,
          PointUpGesture,
          PointLeftGesture,
          PointDownGesture,
          RaisedFistGesture,
        ]);
        const gesture = await GE.estimate(hand[0].landmarks, 8);
        if (gesture.gestures !== undefined && gesture.gestures.length > 0) {
          const confidence = gesture.gestures.map(
            (prediction) => prediction.score
          );
          const maxConfidence = confidence.indexOf(
            Math.max.apply(null, confidence)
          );
          setEmoji(gesture.gestures[maxConfidence].name);
        }
      }

      // Draw mesh
      const ctx = canvasRef.current.getContext("2d");
      drawHand(hand, ctx);
    }
  };

  runHandpose();

  return (
    <div className="App">
      <header className="Gesture-header">
        <Webcam
          ref={webcamRef}
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            zindex: 9,
            width: "60vw",
            height: "70vh",
          }}
        />
        <canvas
          ref={canvasRef}
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            zindex: 9,
            width: "60vw",
            height: "70vh",
          }}
        />

        {emoji !== null ? (
          <p
            // src={images[emoji]}
            style={{
              position: "absolute",
              marginLeft: "auto",
              marginRight: "auto",
              left: 0,
              bottom: 500,
              right: 0,
              textAlign: "center",
              height: 400,
            }}
          >
            {images[emoji]}
          </p>
        ) : (
          ""
        )}
      </header>
    </div>
  );
}

export default Gesture;

// import React, { useRef, useState, useEffect } from "react";
// import * as handpose from "@tensorflow-models/handpose";
// import Webcam from "react-webcam";
// import "../App.css";
// import { drawHand } from "../utils.js";
// import * as fp from "fingerpose";
// import ThumbsDownGesture from "../Gestures/ThumbsDown.js";
// import MiddleFingerGesture from "../Gestures/MiddleFinger.js";
// import OKSignGesture from "../Gestures/OKSign.js";
// import PinchedFingerGesture from "../Gestures/PinchedFinger.js";
// import PinchedHandGesture from "../Gestures/PinchedHand.js";
// import RaisedHandGesture from "../Gestures/RaisedHand.js";
// import LoveYouGesture from "../Gestures/LoveYou.js";
// import RockOnGesture from "../Gestures/RockOn.js";
// import CallMeGesture from "../Gestures/CallMe.js";
// import PointUpGesture from "../Gestures/PointUp.js";
// import PointDownGesture from "../Gestures/PointDown.js";
// import PointRightGesture from "../Gestures/PointRight.js";
// import PointLeftGesture from "../Gestures/PointLeft.js";
// import RaisedFistGesture from "../Gestures/RaisedFist.js";
// import victory from "../Assets/images/victory.png";
// import thumbs_up from "../Assets/images/thumbs_up.png";
// import thumbs_down from "../Assets/images/thumbs_down.png";
// import middle_finger from "../Assets/images/middle_finger.png";
// import ok_sign from "../Assets/images/ok_sign.png";
// import pinched_finger from "../Assets/images/pinched_finger.png";
// import pinched_hand from "../Assets/images/pinched_hand.png";
// import raised_hand from "../Assets/images/raised_hand.png";
// import love_you from "../Assets/images/love_you.png";
// import rock_on from "../Assets/images/rock_on.png";
// import call_me from "../Assets/images/call_me.png";
// import point_up from "../Assets/images/point_up.png";
// import point_down from "../Assets/images/point_down.png";
// import point_left from "../Assets/images/point_left.png";
// import point_right from "../Assets/images/point_right.png";
// import raised_fist from "../Assets/images/raised_fist.png";

// function Gesture() {
//   const webcamRef = useRef(null);
//   const canvasRef = useRef(null);

//   const [emoji, setEmoji] = useState(null);
//   const images = {
//     thumbs_up: thumbs_up,
//     victory: victory,
//     thumbs_down: thumbs_down,
//     middle_finger: middle_finger,
//     ok_sign: ok_sign,
//     pinched_finger: pinched_finger,
//     pinched_hand: pinched_hand,
//     raised_hand: raised_hand,
//     love_you: love_you,
//     rock_on: rock_on,
//     call_me: call_me,
//     point_up: point_up,
//     point_down: point_down,
//     point_left: point_left,
//     point_right: point_right,
//     raised_fist: raised_fist,
//   };

//   useEffect(() => {
//     let isMounted = true;

//     const runHandpose = async () => {
//       const net = await handpose.load();
//       setInterval(() => {
//         detect(net);
//       }, 100);
//     };

//     const detect = async (net) => {
//       if (webcamRef.current && webcamRef.current.video.readyState === 4) {
//         const video = webcamRef.current.video;
//         const videoWidth = video.videoWidth;
//         const videoHeight = video.videoHeight;

//         webcamRef.current.video.width = videoWidth;
//         webcamRef.current.video.height = videoHeight;
//         canvasRef.current.width = videoWidth;
//         canvasRef.current.height = videoHeight;

//         const hand = await net.estimateHands(video);

//         if (hand.length > 0) {
//           const GE = new fp.GestureEstimator([
//             fp.Gestures.VictoryGesture,
//             fp.Gestures.ThumbsUpGesture,
//             ThumbsDownGesture,
//             MiddleFingerGesture,
//             OKSignGesture,
//             PinchedFingerGesture,
//             PinchedHandGesture,
//             RaisedHandGesture,
//             LoveYouGesture,
//             RockOnGesture,
//             CallMeGesture,
//             PointRightGesture,
//             PointUpGesture,
//             PointLeftGesture,
//             PointDownGesture,
//             RaisedFistGesture,
//           ]);
//           const gesture = await GE.estimate(hand[0].landmarks, 8);

//           if (gesture.gestures !== undefined && gesture.gestures.length > 0) {
//             const confidence = gesture.gestures.map(
//               (prediction) => prediction.score
//             );
//             const maxConfidence = confidence.indexOf(
//               Math.max.apply(null, confidence)
//             );
//             if (isMounted) {
//               setEmoji(gesture.gestures[maxConfidence].name);
//             }
//           }
//         }

//         const ctx = canvasRef.current.getContext("2d");
//         drawHand(hand, ctx);
//       }
//     };

//     runHandpose();

//     return () => {
//       isMounted = false;
//     };
//   }, []); // Empty dependency array to run the effect only once on component mount

//   return (
//     <div className="App">
//       <header className="App-header">
//         <Webcam
//           ref={webcamRef}
//           style={{
//             position: "absolute",
//             marginLeft: "auto",
//             marginRight: "auto",
//             left: 0,
//             right: 0,
//             textAlign: "center",
//             zIndex: 9,
//             width: 640,
//             height: 480,
//           }}
//         />
//         <canvas
//           ref={canvasRef}
//           style={{
//             position: "absolute",
//             marginLeft: "auto",
//             marginRight: "auto",
//             left: 0,
//             right: 0,
//             textAlign: "center",
//             zIndex: 9,
//             width: 640,
//             height: 480,
//           }}
//         />

//         {emoji !== null ? (
//           <img
//             src={images[emoji]}
//             style={{
//               position: "absolute",
//               marginLeft: "auto",
//               marginRight: "auto",
//               left: 400,
//               bottom: 500,
//               right: 0,
//               textAlign: "center",
//               height: 100,
//             }}
//           />
//         ) : (
//           ""
//         )}
//       </header>
//     </div>
//   );
// }

// export default Gesture;
