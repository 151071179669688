// import "../Styles/Explore.css";

// const Explore = () => {
//   return (
//     <div>
//       {/* <a href={"Crop"}>Crop</a>
//       <a href={"Gesture"}>Gesture</a>

//       <a href={"ObjectDetection"}>ObjectRecognition</a> */}
//     </div>
//   );
// };

// export default Explore;

import expone from "../Assets/images/expone.png";
import exptwo from "../Assets/images/exptwo.png";
import expthree from "../Assets/images/expthree.png";
import expfour from "../Assets/images/expfour.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import colorSharp from "../assets/images/color-sharp.png";
import AOS from "aos";
import React, { useEffect } from "react";
import "aos/dist/aos.css";
import "../Styles/Explore.css";
import Navbar from "../Components/Navbar";

const Explore = () => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 664 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 664, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="skill" id="skills">
      <Navbar />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2 data-aos="fade">Explore Zone</h2>
              <p className="product_card">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.<br></br> Lorem Ipsum has been the industry's standard
                dummy text.
              </p>
              <Carousel
                responsive={responsive}
                infinite={true}
                className="owl-carousel owl-theme skill-slider"
              >
                <div className="exppro" data-aos="flip-up">
                  <div className="expimg">
                    <img src={expone} alt="Image1" />
                  </div>
                  <div className="exptext">
                    <h4>Intelligent Crop Prediction</h4>{" "}
                    <p>Lorem Ipsum is simply dummy text</p>
                    <a className="btn" href={"Crop"}>
                      Click Here
                    </a>
                  </div>
                </div>
                <div className="exppro" data-aos="flip-down">
                  <div className="expimg">
                    <img src={exptwo} alt="Image1" />
                    <div className="exptext">
                      <h4>Hand Gesture Detection</h4>{" "}
                      <p>Lorem Ipsum is simply dummy text</p>
                      <a className="btn" href={"Gesture"}>
                        Click Here
                      </a>
                    </div>
                  </div>
                </div>
                <div className="exppro" data-aos="flip-down">
                  <div className="expimg">
                    <img src={expthree} alt="Image1" />
                    <div className="exptext">
                      <h4>Object Identification</h4>{" "}
                      <p>Lorem Ipsum is simply dummy text</p>
                      <a className="btn" href={"ObjectDetection"}>
                        Click Here
                      </a>
                    </div>
                  </div>
                </div>
                <div className="exppro" data-aos="flip-down">
                  <div className="expimg">
                    <img src={expfour} alt="Image1" />
                    <div className="exptext">
                      <h4>Text Sentiment Analysis</h4>{" "}
                      <p>Lorem Ipsum is simply dummy text</p>
                      <a className="btn" href={"SentimentAnalysis"}>
                        Click Here
                      </a>
                    </div>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Explore;
